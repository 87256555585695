export const contactsData = {
    email: 'camcoenjarts@gmail.com',
    phone: '+2262365558',
    address: 'Toronto, Ontario ',
    github: 'https://github.com/cam-cc',
    facebook: 'https://www.facebook.com/',
    linkedIn: 'https://www.linkedin.com/in/cameron-coenjarts-6376611a7',
    twitter: 'https://twitter.com',
    medium: 'https://medium.com',
    stackOverflow: 'https://stackoverflow.com',
    devUsername: "cam-cc"
}